import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionContact from '../components/sections/contact';
import SectionMap from '../components/sections/map';
import SectionInstagram from '../components/sections/instagram';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IIndexProps {
    readonly data: {
        page: IPage;
    };
}

const Index: React.FC<IIndexProps> = ({ data }) => {
    const { page } = data;

    const sections = getSectionsWithRepeatIndex(page.sections).map((section) => {
        return section.type === 'article-preview'
            ? { ...section, settings: { ...section.settings, isMain: true } }
            : section;
    });
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    return (
        <MainLayout>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default Index;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'contact': SectionContact,
    'map': SectionMap,
    'instagram': SectionInstagram,
    'footer': SectionFooter,
};