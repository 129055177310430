import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import useTranslations from '../../hooks/use-translations';

import {
    sectionContainer,
    box,
    content,
    name as nameClass,
    address as addressClass,
    link,
    directions,
    icon,
} from './map.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

interface IMapSection extends ISection {
    items: {
        url: string;
        apiKey: string;
        name: string;
        address: string;
        center: {
            lat: string;
            lng: string;
        };
        containerStyle: {
            height: string;
            width: string;
        };
        zoom: string;
    };
}

interface IMapProps {
    className?: string;
    section: IMapSection;
}

const Map: React.FC<IMapProps> = ({ className = '', section }) => {
    const { sectionId, css, style } = section;
    const { url, apiKey, name, address, center, containerStyle, zoom } = section.items;
    const t = useTranslations('Map');

    const coordinates = {
        lat: parseFloat(center.lat),
        lng: parseFloat(center.lng),
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
    });

    const directionUrl = url.replace('/place/', '/dir//');

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionContainer}`}
            css={css}
            style={style}
        >
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordinates}
                    zoom={parseFloat(zoom)}
                >
                    <div className={box}>
                        <div className={content}>
                            <p className={nameClass}>{name}</p>
                            <p className={addressClass}>{address}</p>
                            <a className={link} target="_blank" href={url} rel="noreferrer">
                                {t.largeMap}
                            </a>
                        </div>
                        <div>
                            <a
                                className={directions}
                                target="_blank"
                                href={directionUrl}
                                rel="noreferrer"
                            >
                                <div className={icon} />
                                {t.directions}
                            </a>
                        </div>
                    </div>
                    <Marker
                        position={coordinates}
                        onClick={() => {
                            window.open(url, '_blank');
                        }}
                    />
                </GoogleMap>
            ) : null}
        </Section>
    );
};

export default React.memo(Map);
